@media only print {
    $form-check-input-checked-color: black;

    @page {
        margin-left: 1cm;
        margin-right: 1cm;
    }

    @page :footer {
        display: none
    }

    @page :header {
        display: none
    }

    .print {
        display: block;
    }

    .btn,
    .button-container,
    .fa,
    .text-danger,
    .alert,
    .nouveau-document,
    textarea,
    .aide-phv {
        display: none !important;
    }

    a {
        color: black;
        text-decoration: none !important;
    }

    input {
        min-width: none !important;
    }

    label {
        opacity: 1 !important;
    }

    input:not([type="checkbox"]),
    select {
        border: 0 !important;
        max-width: 30em;
        display: inline-block !important;
        resize: none;

        &:not(.input-print-keep-margin) {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        &[type="date"] {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    input[type="checkbox"] {
        background-color: black !important;
    }

    label {
        vertical-align: top !important;
    }

    .form-check-input {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        border: 1px solid white !important;
        background-color: black !important;
        //Le invert permet de faire afficher le background de la ckeckbox sinon on ne verrait pas le crochet
        filter: invert(1) !important;
        opacity: 1 !important;
    }

    .textarea-print {
        display: block;
    }

    input[type=number],
    input[type=date] {
        appearance: textfield;
    }

    .mnt,
    .mnt-th,
    .num {
        .form-control {
            width: 5em !important;
            padding: 0;
        }
    }

    .print-break-after {
        break-after: page;
    }

    .label-nb-phv {
        width: auto;

        +.form-group {
            float: right;
        }
    }
}